import vuetify from '@/plugins/vuetify';
import { Enums } from '@flightscope/baseball-stats';


const formatValue = (value) => value || '-';

const hoverPointDataProvider = (pointData, xTitle, yTitle, mightHaveScoring, mightHaveNewScoringData) => {
  const pitcherJerseyNumber = pointData.pitcherJerseyNumber ? ` (#${pointData.pitcherJerseyNumber})` : '';
  const pitcherFormattedNameWithJersey = pointData.pitcherFormattedName
    ? `: ${pointData.pitcherFormattedName}${pitcherJerseyNumber}`
    : '';
  const batterJerseyNumber = pointData.batterJerseyNumber ? ` (#${pointData.batterJerseyNumber})` : '';
  const batterFormattedNameWithJersey = pointData.batterFormattedName
    ? `: ${pointData.batterFormattedName}${batterJerseyNumber}`
    : '';

  const newScoringDataRows = mightHaveNewScoringData
    ? [
      { label: vuetify.framework.lang.t('$vuetify.tooltip.pitchCall'), value: formatValue(pointData.PitchCall) },
      { label: vuetify.framework.lang.t('$vuetify.tooltip.plateAppearanceResult'), value: formatValue(pointData.plateAppearanceResult) },
    ]
    : [
      {
        label: vuetify.framework.lang.t('$vuetify.tooltip.pitchResult'),
        value: formatValue(pointData.pitchResultType),
      },
    ];

  const returnData = [
    {
      name: '$vuetify.tooltip.pointData',
      groupLink: pointData.datatableLink,
      groupLinkText: '$vuetify.tooltip.seeDetailsTable',
      videoDialogText: '$vuetify.tooltip.videoDialog',
      rows: [
        { label: xTitle, value: formatValue(pointData.xFormatted) },
        { label: yTitle, value: formatValue(pointData.yFormatted) },
      ],
    },
    {
      name: `${vuetify.framework.lang.t('$vuetify.tooltip.pitcher')}${pitcherFormattedNameWithJersey}`,
      rows: [
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.pitcherHand'),
          value: formatValue(pointData.pitcherHandedness),
        },
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.pitchSpeed'),
          value: formatValue(pointData.pitchSpeedConverted),
        },
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.pitchSpin'),
          value: formatValue(pointData.pitchSpinConverted),
        },
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.strikeZoneSector'),
          value: formatValue(pointData.strikeZoneSector),
        },
        { label: vuetify.framework.lang.t('$vuetify.tooltip.pitchCount'), value: formatValue(pointData.pitchCount) },
        ...newScoringDataRows,
        { label: vuetify.framework.lang.t('$vuetify.tooltip.pitchType'), value: formatValue(pointData.pitchType) },
      ],
    },
    {
      name: `${vuetify.framework.lang.t('$vuetify.tooltip.batter')}${batterFormattedNameWithJersey}`,
      rows: [
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.battedBallType'),
          value: formatValue(pointData.battedBallType),
        },
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.batterHand'),
          value: formatValue(pointData.batterHandedness),
        },
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.hitDistance'),
          value: formatValue(pointData.hitCarryFormatted),
        },
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.hitExitSpeed'),
          value: formatValue(pointData.hitExitSpeedFormatted),
        },
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.hitLaunchV'),
          value: formatValue(pointData.hitLaunchVFormatted),
        },
        {
          label: vuetify.framework.lang.t('$vuetify.tooltip.plateAppearance'),
          value: formatValue(pointData.plateAppearance),
        },
      ],
    },
  ];

  if (mightHaveScoring) {
    returnData.push({
      name: vuetify.framework.lang.t('$vuetify.tooltip.scoringData'),
      rows: [
        {
          label: vuetify.framework.lang.t(`$vuetify.${Enums.PitchData.BeforePlayInning.key}`),
          value: formatValue(pointData.beforePlayInningValueFormatted),
        },
        {
          label: vuetify.framework.lang.t(`$vuetify.${Enums.PitchData.BeforePlayBalls.key}`),
          value: formatValue(pointData.beforePlayBallsValueFormatted),
        },
        {
          label: vuetify.framework.lang.t(`$vuetify.${Enums.PitchData.BeforePlayStrikes.key}`),
          value: formatValue(pointData.beforePlayStrikesValueFormatted),
        },
        {
          label: vuetify.framework.lang.t(`$vuetify.${Enums.PitchData.BeforePlayRunnersOn.key}`),
          value: formatValue(pointData.beforePlayRunnersOnValueFormatted),
        },
        {
          label: vuetify.framework.lang.t(`$vuetify.${Enums.PitchData.BeforePlayOuts.key}`),
          value: formatValue(pointData.beforePlayOutsValueFormatted),
        },
      ],
    });
  }

  return returnData;
};

export default hoverPointDataProvider;
