import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Enums } from '@flightscope/baseball-stats';
import { measurementSystem, unitsConverter } from '@/plugins/converter';
import HeatMapFilters from '@/components/mixins/charts/HeatMapFilters';
import { dataProvider } from '../Injectors';
import HasPlayers from '@/components/mixins/HasPlayers'
import tooltipDataProvider from '@/providers/TooltipDataProvider';
import hoverPointDataProvider from '@/providers/HoverPointDataProvider';
import HoveredPointTooltip from '@/components/ui/tooltips/HoveredPointTooltip.vue';
import { captureException } from '@sentry/vue';

const defaultPropsGuard = (props) => {
  return props && props.hasOwnProperty('data') && props.data.length;
};

export default Vue.extend({
  // TODO: Refactor name to ChartReport
  name: 'HeatmapReport',

  components: {
    HoveredPointTooltip,
  },

  mixins: [HeatMapFilters, HasPlayers],

  inject: {
    dataProvider: {
      from: dataProvider,
    },
  },

  props: {
    reloadIndicator: {
      type: [Number],
    },

    chartComponent: {
      type: Function,
      default: () => import('@/components/ui/charts/plotly/PlotlyGraph.vue'),
    },

    report: {
      type: Array,
      default: () => [],
    },

    player: {
      type: Object,
    },

    dynamicFilters: {
      type: Object,
      default: () => {},
    },

    chartConf: {
      type: Object,
      default: () => {},
    },
    reportLoading: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: ['reloadIndicator'],
    event: 'input',
  },

  data() {
    return {
      series: undefined,
      counter: 0,
      props: undefined,
      provider: undefined,
      loading: false,
      filters: undefined,
      error: '',
      pointData: {},
    };
  },

  computed: {
    reload: {
      get() {
        return this.reloadIndicator || 0;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    ...mapGetters(['selectedUnitsSystem']),
    isMetric() {
      return this.selectedUnitsSystem.system === measurementSystem.METRIC;
    },
    watchedData() {
      return {
        report: this.report,
        filters: this.filters,
        chartConf: this.chartConf,
        isMetric: this.isMetric,
      };
    },
    chartSettings() {
      // TODO: Proper handle in future
      return this.chartConf.conf;
    },
    mightHaveScoring() {
      // TODO: Proper handle in future
      return false;
    },
    mightHaveNewScoringData() {
      return false;
    },
    hoveredPointData() {
      if (this.loading || !this.chartSettings.axis) {
        return undefined;
      }

      return hoverPointDataProvider(
        this.pointData,
        this.chartSettings.axis.xaxis.title,
        this.yAxisTitle,
        this.mightHaveScoring,
        this.mightHaveNewScoringData
        );
      },
      hoveredPointMedia() {
        // TODO: Proper handle in future
        if (this.loading) {
          return undefined;
        }

      return undefined;
    },
    yAxisTitle() {
      if (this.chartSettings.axis.yaxis) {
        return this.chartSettings.axis.yaxis.title;
      }
      return this?.props?.layout.yaxis.title.textWithoutUnit;
    },
    isTooltipVisible() {
      return this.chartSettings.enabledHover && this.hoveredPointData;
    }
  },

  methods: {
    watchHandler(newVal, oldVal) {
      let { key, conf } = this.chartConf;

      if (this.provider && this.player && !this.loading) {
        try {
          this.loading = true;
          this.props = undefined;

          if (!this.provider.filterGuard(this.filters)) {
            this.loading = false;
            return undefined;
          }

          conf.playerName = this.player.FormattedName;
          this.provider.setConf(conf);

          if (conf.hasOwnProperty('dynamicAxis')) {
            conf.convertDimensions = conf.convertDimensions
              .map(({ dimension, conversionType, baseOn }) => {
                let enumObj;
                if (baseOn) {
                  enumObj = this.filters[baseOn];
                  conversionType = enumObj.type;
                }
                return { dimension, conversionType, enumObj, baseOn };
              });
            this.provider.setConf(conf);
          }

          this.series = this.provider.getMappedSeries(this.report, this.filters, {
            isMetric: this.isMetric,
            unitSystem: this.selectedUnitsSystem.system,
          });

          if (this.chartConf.key === 'batting-spray-chart') {
            this.adjustBattingSprayChartHover();
          }

          this.props = this.provider.getProps(this.series, { unitSystem: this.selectedUnitsSystem.system });

          this.loading = false;
          this.counter++;
        } catch (e) {
          captureException(e);
          this.$log.error(e);
          this.error = e;
        }
      }
    },
    /**
     *
     * @param {Object}
     * @returns {Object}
     */
    getClosestPoint(event) {
      const mouseX = event.hasOwnProperty('xvals') ? event.xvals[0] : undefined;
      const mouseY = event.hasOwnProperty('yvals') ? event.yvals[0] : undefined;
      let closestPoint = event.points[0];

      if (event.points.length > 1 && typeof mouseX !== 'undefined' && typeof mouseY !== 'undefined') {
        // it can happen that we have more points around mouseX and mouseY - just imagine overlapping points from multiple series
        const pointToMouse = event.points
          // get numbers only
          .filter((p) => !Number.isNaN(p.x) && !Number.isNaN(p.y))
          // calculate distance
          .map((p) => Math.sqrt(Math.pow(Math.abs(mouseX - p.x) + Math.abs(mouseY - p.y), 2)));

        if (pointToMouse.length) {
          // search for smallest value (first item after sorting)
          closestPoint = event.points[pointToMouse.indexOf([].concat(pointToMouse).sort()[0])];
        }
      }

      return closestPoint;
    },
    /**
     *
     * @param {Object}
     * @returns {(Object|undefined)}
     */
    getHitResult(result) {
      if (!result) {
        return undefined;
      }

      const isResultHitType = result.ResultType === 'H';
      const isResultPitchType = result.ResultType === 'P';

      if (isResultPitchType) {
        return this.getResult(result.HitResultID);
      }

      if (isResultHitType) {
        return result;
      }
    },
    /**
     *
     * @param {Object}
     * @returns {(Object|undefined)}
     */
    getPitchResult(result) {
      if (!result) {
        return undefined;
      }

      const isResultHitType = result.ResultType === 'H';
      const isResultPitchType = result.ResultType === 'P';

      if (isResultPitchType) {
        return result;
      }

      if (isResultHitType) {
        return this.getResult(result.PitchResultID);
      }
    },
    /**
     *
     * @param {Number}
     * @returns {(Object|undefined)}
     */
    getResult(resultId) {
      if (typeof resultId !== 'number' || !Array.isArray(this.report)) {
        return undefined;
      }
      return this.report.find(r => r.ResultID === resultId);
    },
    async hoverHandler(event) {
      if (!this.chartSettings.enabledHover || !this.series?.length || !event || !event.hasOwnProperty('points')) {
        return;
      }

      let closestPoint = this.getClosestPoint(event);

      if (!closestPoint) {
        this.$log.debug(`Closest Point was not found.`);
        return;
      }

      const resultId = closestPoint.customdata;
      if (!resultId) {
        this.$log.debug(`Custom Data for Closest Point was not found.`);
        return;
      }

      const resultFromEvent = this.getResult(resultId);

      if (!resultFromEvent) {
        this.$log.debug(`Matching result for ResultId: ${resultId} was not found.`);
        return;
      }

      let batter;
      let pitchResult = this.getPitchResult(resultFromEvent);
      let hitResult = this.getHitResult(resultFromEvent);
      let pitcher = await this.PlayerRepo.get(pitchResult.TagID);
      if (hitResult) {
        batter = await this.PlayerRepo.get(hitResult.TagID);
      }

      // let's define a fallback value from the chart
      const rawPoint = [closestPoint.x, closestPoint.y];
      let hasSiValues = false;

      let xPrecision = this.chartSettings.convertDimensions[0].conversionPrecision || 2
      let yPrecision = this.chartSettings.convertDimensions[1].conversionPrecision || 2;

      // try to get data directly from untouched source in SI, important for pitcher/batter's performance tracking
      if (this.series[0]?.key && resultFromEvent[Enums.ResultData.Data.key] && resultFromEvent[Enums.ResultData.Data.key][this.series[0].key]) {
        // so the key will now contain data parameter key of Y axis, X is always numeric (pitch count or PA)
        hasSiValues = true;
        rawPoint[1] = resultFromEvent[Enums.ResultData.Data.key][this.series[0].key];
        xPrecision = 0;
      }

      const media = []; // TODO: Consider getting media in future

      this.pointData = tooltipDataProvider(
        rawPoint,
        this.mightHaveScoring,
        this.mightHaveNewScoringData,
        hitResult,
        pitchResult,
        pitcher,
        batter,
        this.selectedUnitsSystem.system,
        this.chartSettings.convertDimensions[0].conversionType,
        this.chartSettings.convertDimensions[1].conversionType,
        xPrecision,
        yPrecision,
        media,
        hasSiValues,
        true
      );
    },
    getCarry(resultID) {
      const result = this.getResult(resultID);

      if (!result) {
        return '-';
      }

      const hitResult = this.getHitResult(result);
      if (!hitResult) {
        this.$log.debug(`Hit Result for ResultId: ${resultId} was not found.`);
        return '-';
      }

      const hitResultData = hitResult[Enums.ResultData.Data.key];
      let carryDistance = hitResultData[Enums.HitData.CarryDistance.key];
      if (typeof carryDistance !== 'number') {
        const landingSideR = hitResultData[Enums.HitReprocessed.LandingSideR.key];
        const LandingDistanceR = hitResultData[Enums.HitReprocessed.LandingDistanceR.key];
        if (typeof landingSideR !== 'undefined' && LandingDistanceR) {
          carryDistance = Math.sqrt(Math.pow(landingSideR, 2) + Math.pow(LandingDistanceR, 2));
        }
      }
      const carryDistanceFormatted = unitsConverter
      .convertType(carryDistance, Enums.HitData.CarryDistance.type, this.selectedUnitsSystem.system)
      .formatWithSymbol('-', 2);
      return carryDistanceFormatted;
    },
    adjustBattingSprayChartHover() {
      this.series = this.series.map(serie => {
        serie.text = serie.customdata.map(resultID => `Carry: ${this.getCarry(resultID)}`);
        return serie;
      });
    }
  },

  mounted() {
    let { key, conf } = this.chartConf;
    let { overloads, ...basicConf } = conf;

    this.propsGuard = defaultPropsGuard;

    if (overloads) {
      let { propsGuard } = overloads;

      this.propsGuard = propsGuard ? propsGuard : defaultPropsGuard;
    }

    this.provider = new this.dataProvider(key, basicConf, overloads);
    this.watchHandler();
  },

  watch: {
    dynamicFilters: {
      handler(newVal) {
        this.filters = this.dynamicFilters;
        this.pointData = {};
      },
      immediate: true,
      deep: true,
    },
    watchedData: {
      handler: 'watchHandler',
      immediate: true,
      deep: true,
    },
  },
});
