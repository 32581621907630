import { Providers, Enums } from '@flightscope/baseball-stats';
import vuetify from '@/plugins/vuetify';
import { unitsConverter } from '@/plugins/converter';
import { sanitizeGuid } from '@/utils/helpers';
import { Player, FormattedResult } from '@/models/orm/Hierarchy';
import { convertUnit, unitSymbolProvider, convertUnitNoSymbol } from '@/filters/units';
import get from 'lodash/get';

/** @typedef {import('../global').PointData} PointData */

/**
 *
 * @param {number[]} rawPoint
 * @param {boolean} mightHaveScoring
 * @param {boolean} mightHaveNewScoringData
 * @param {(null|FormattedResult)} hitResult
 * @param {(null|FormattedResult)} pitchResult
 * @param {Player} pitcher
 * @param {Player} batter
 * @param {*} unitsSystem
 * @param {*} xConversion
 * @param {*} yConversion
 * @param {*} xPrecision
 * @param {*} yPrecision
 * @param {*} videoCollection
 * @returns {PointData}
 */
const tooltipDataProvider = (
  rawPoint,
  mightHaveScoring, // move to state of tooltip provider
  mightHaveNewScoringData,
  hitResult,
  pitchResult,
  pitcher,
  batter,
  unitsSystem,
  xConversion, // move to the state of tooltip formatter
  yConversion, // move to the state of tooltip formatter
  xPrecision, // move to the state of tooltip formatter
  yPrecision, // move to the state of tooltip formatter
  videoCollection = [],
  convertRawPoint = true,
  detectGameType,
) => {
  /** @type {PointData} */
  const pointData = {};
  const dataKey = Enums.ResultData.Data.key;

  if (convertRawPoint) {
    // Set primary chart data. X and y with their units.
    pointData.xFormatted = convertUnit(
      rawPoint[Enums.SeriesDimension.X - 1],
      xConversion,
      unitsSystem,
      { noValueFallback: '-', fractionalDigits: xPrecision }
    );
    pointData.yFormatted = convertUnit(
      rawPoint[Enums.SeriesDimension.Y - 1],
      yConversion,
      unitsSystem,
      { noValueFallback: '-', fractionalDigits: yPrecision }
    );
  } else {
    // Set primary chart data. X and y with their units.
    const xUnit = unitSymbolProvider(xConversion, unitsSystem, 0);
    const yUnit = unitSymbolProvider(yConversion, unitsSystem, 0);
    let xValue = convertUnitNoSymbol(
      rawPoint[Enums.SeriesDimension.X - 1], 'NUMBER',
      unitsSystem,
      { fractionalDigits: xPrecision }
    );
    let yValue = convertUnitNoSymbol(
      rawPoint[Enums.SeriesDimension.Y - 1], 'NUMBER',
      unitsSystem,
      { fractionalDigits: yPrecision }
    );
    pointData.xFormatted = xValue ? `${xValue} ${xUnit}` : '-';
    pointData.yFormatted = yValue ? `${yValue} ${yUnit}` : '-';
  }

  // Set pitcher data
  if (pitcher) {
    pointData.pitcherFormattedName = pitcher.FormattedName;
    pointData.pitcherJerseyNumber = pitcher.JerseyNumber;
  }

  // Set batter data
  if (batter) {
    pointData.batterFormattedName = batter.FormattedName;
    pointData.batterJerseyNumber = batter.JerseyNumber;
  }

  let resultGuid;

  // Get pitch result data
  if (pitchResult) {
    pointData.plateAppearance = pitchResult[dataKey][Enums.PitchReprocessed.PlateAppearanceR.key] || null;

    pointData.pitchSpeedConverted = unitsConverter
      .convertType(pitchResult[dataKey][Enums.PitchData.Speed.key], Enums.PitchData.Speed.type, unitsSystem)
      .formatWithSymbol('-', 2);

    pointData.pitchSpinConverted = unitsConverter
      .convertType(pitchResult[dataKey][Enums.PitchData.Spin.key], Enums.PitchData.Spin.type, unitsSystem)
      .formatWithSymbol('-', 0);

    pointData.strikeZoneSector = pitchResult[dataKey][Enums.PitchReprocessed.StrikeZonePositionR.key];

    pointData.pitchCount = pitchResult[dataKey][Enums.PitchReprocessed.PitchCountR.key];

    pointData.pitchResultType = vuetify.framework.lang.t(
      `$vuetify.pitchResultTypes.${pitchResult.Data[Enums.PitchData.Result.key]}`
    );

    pointData.pitchType = vuetify.framework.lang.t(
      `$vuetify.pitchTypes.${pitchResult.Data[Enums.PitchData.Classification.key]}`
    );

    pointData.pitcherHandedness = vuetify.framework.lang.t(
      `$vuetify.handedness.${pitchResult[dataKey][Enums.PitchData.Handedness.key]}`
    );

    resultGuid = pitchResult[dataKey]['GUID'] || resultGuid;
  }

  // Get hit result data
  if (hitResult) {
    pointData.plateAppearance = hitResult[dataKey][Enums.HitReprocessed.PlateAppearanceR.key] || null;

    pointData.hitExitSpeedFormatted = unitsConverter
      .convertType(hitResult[dataKey][Enums.HitData.ExitSpeed.key], Enums.HitData.ExitSpeed.type, unitsSystem)
      .formatWithSymbol('-', 2);

    pointData.hitLaunchVFormatted = unitsConverter
      .convertType(hitResult[dataKey][Enums.HitData.LaunchV.key], Enums.HitData.LaunchV.type, unitsSystem)
      .formatWithSymbol('-', 2);

    pointData.hitCarryFormatted = unitsConverter
      .convertType(hitResult[dataKey][Enums.HitData.CarryDistance.key], Enums.HitData.CarryDistance.type, unitsSystem)
      .formatWithSymbol('-', 2);

    pointData.batterHandedness = vuetify.framework.lang.t(
      `$vuetify.handedness.${hitResult[dataKey][Enums.HitData.SwingHandedness.key]}`
    );

    pointData.battedBallType = vuetify.framework.lang.t(
      `$vuetify.battedBallTypes.${hitResult[dataKey][Enums.HitReprocessed.BattedBallTypeR.key]}`
    );

    resultGuid = hitResult[dataKey]['GUID'] || resultGuid;
  }

  if (resultGuid) {
    const params = detectGameType ? { type: 'Detect' } : {};
    pointData.datatableLink = { name: 'session.table', params, hash: `#${sanitizeGuid(resultGuid)}` };
  }

  if (mightHaveScoring) {
    const matchingResult = pitchResult || hitResult;
    const beforePlayInningIsTop = matchingResult[dataKey][Enums.PitchData.BeforePlayInningIsTop.key];
    let beforePlayInningValueFormatted = unitsConverter
      .convertType(
        matchingResult[dataKey][Enums.PitchData.BeforePlayInning.key],
        Enums.PitchData.BeforePlayInning.type,
        unitsSystem
      )
      .formatWithSymbol('', 0);

    // if before play inning value is not empty then add triangle to it
    if (beforePlayInningValueFormatted) {
      beforePlayInningValueFormatted += beforePlayInningIsTop
        ? ` ${Enums.PitchData.BeforePlayInningIsTop.enum.Top.short}`
        : ` ${Enums.PitchData.BeforePlayInningIsTop.enum.Bottom.short}`;
    }

    const beforePlayBallsValueFormatted = unitsConverter
      .convertType(
        matchingResult[dataKey][Enums.PitchData.BeforePlayBalls.key],
        Enums.PitchData.BeforePlayBalls.type,
        unitsSystem
      )
      .formatWithSymbol('', 0);

    const beforePlayStrikesValueFormatted = unitsConverter
      .convertType(
        matchingResult[dataKey][Enums.PitchData.BeforePlayStrikes.key],
        Enums.PitchData.BeforePlayStrikes.type,
        unitsSystem
      )
      .formatWithSymbol('', 0);

    const beforePlayRunnersOn = matchingResult[dataKey][Enums.PitchData.BeforePlayRunnersOn.key];
    let beforePlayRunnersOnValueFormatted = '';
    if (typeof beforePlayRunnersOn !== 'undefined') {
      beforePlayRunnersOnValueFormatted = Providers.EnumValueProvider.getValue(
        beforePlayRunnersOn,
        Enums.RunnersOn,
        Enums.RunnersOn.Unidentified.key,
        undefined,
        'short'
      );
    }

    const beforePlayOutsValueFormatted = unitsConverter
      .convertType(
        matchingResult[dataKey][Enums.PitchData.BeforePlayOuts.key],
        Enums.PitchData.BeforePlayOuts.type,
        unitsSystem
      )
      .formatWithSymbol('', 0);

    pointData.beforePlayInningValueFormatted = beforePlayInningValueFormatted;
    pointData.beforePlayBallsValueFormatted = beforePlayBallsValueFormatted;
    pointData.beforePlayStrikesValueFormatted = beforePlayStrikesValueFormatted;
    pointData.beforePlayRunnersOnValueFormatted = beforePlayRunnersOnValueFormatted;
    pointData.beforePlayOutsValueFormatted = beforePlayOutsValueFormatted;
  }

  if (mightHaveNewScoringData) {
    const pitchCall = get(pitchResult, [Enums.ResultData.Data.key, Enums.ScoringData.PitchCall.key]);
    const plateAppearanceResult = get(pitchResult, [Enums.ResultData.Data.key, Enums.ScoringData.PlateAppearanceResult.key]);

    if (pitchCall) {
      pointData.PitchCall = Providers.EnumValueProvider.getValue(pitchCall, Enums.PitchCall, Enums.PitchCall.Unidentified).name;
    }

    if (plateAppearanceResult) {
      pointData.plateAppearanceResult = Providers.EnumValueProvider.getValue(plateAppearanceResult, Enums.PlateAppearanceResult, Enums.PlateAppearanceResult.NotAvailable).name;
    }
  }

  pointData.media = undefined;

  // TODO:unify with the formatter from `DataTable.vue`
  if (videoCollection.length) {
    let date = hitResult ? hitResult.CreateDate : pitchResult ? pitchResult.CreateDate : '';
    // let pitcherName = pitcher ? pitcher.FormattedNameWithJersey : '';
    // let batterName = batter ? batter.FormattedNameWithJersey : '';

    // let pitchCount = pointData.pitchResult || undefined;
    // let plateAppearance = pointData.plateAppearance || undefined;

    // pitchCount = pitchCount ? `(P: ${pitchCount})` : '';
    // plateAppearance = plateAppearance ? `(PA: ${plateAppearance})` : '';

    // pitcherName = [pitcherName, pitchCount].join(' ');
    // batterName = [batterName, plateAppearance].join(' ');

    // let title = `${pitcherName} vs ${batterName}: ${pointData.pitchType || ''} / ${pointData.pitchResultType || ''}`;

    pointData.media = {
      date,
      id: hitResult?.ResultID || pitchResult?.ResultID,
      // title,
      media: videoCollection,
    };
  }

  return pointData;
};

export default tooltipDataProvider;
