import each from 'lodash/each';
import PlayerFiltersTranslations from '../../../enums/PlayerFiltersTranslations';
import vuetify from '../../../plugins/vuetify';

export default {
  computed: {
    // This computed property is used to display label: value pair's in chart component
    currentFilters() {
      const heatMapFilters = [];
      // Combine dynamic and static filters together
      const combinedFilters = { ...this.staticFilters, ...this.dynamicFilters };
      each(combinedFilters, (value, key) => {
        // If filter key is defined in filters translations and has value then push it to heatMapFilters
        if (PlayerFiltersTranslations[key] && value) {
          const label = vuetify.framework.lang.t(`$vuetify.${PlayerFiltersTranslations[key]}`);
          const displayValue = Array.isArray(value) ? value.join(' ~ ') : value;
          heatMapFilters.push({ label, value: displayValue });
        }
      });

      return heatMapFilters;
    },
  },
};
