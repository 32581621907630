export default {
  name: 'HoveredPointTooltip',

  props: {
    columns: {
      type: Array,
      required: true,
    },

    media: {
      type: Object,
    },
  },

  data() {
    return {
      showVideosDialog: false,
      videosDialogContent: {},
    };
  },

  computed: {
    /**
     * Get longest label
     * @returns {number}
     */
    labelLetterCount() {
      return this.columns.reduce(
        (max, current) =>
        current.rows.reduce((innerMax, innerCurrent) => {
          if (innerMax < innerCurrent.label?.length) {
              return innerCurrent.label.length;
            }

            return innerMax;
          }, max),
        0
      );
    },
    readComputedDimensions() {
      const styles = {};
      styles['--letter-count'] = this.labelLetterCount;

      return styles;
    },

    videosDialogMaxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 220;
        case 'sm':
          return 500;
        case 'md':
          return 800;
        case 'lg':
          return 1200;
        case 'xl':
          return 1500;
        default:
          return null;
      }
    },
  },

  methods: {
    playVideo(item) {
      this.videosDialogContent = item;
      this.showVideosDialog = true;
    },
  },
};
